<template>
    <div style="height:100%;">

        <!-- <a-breadcrumb style="margin: 6px 10px 0 ;">
            <a-button style="float:right" href="#" onclick="window.history.go(-1); return false;">返回</a-button>
        </a-breadcrumb> -->
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;width:calc(100% - 32px);min-width:max-content">
                <a-card style="width:100%;min-height:100%;" :bordered="false">
                    <a-form-model layout="horizontal" style="padding:10px 00px;height:auto;width:800px;" :rules="rules" ref="ruleForms" :model="ExtensionModel">
                        <a-form-model-item label="文章标题" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="NewsTitle">
                            <a-input placeholder="请输入标题" v-model="ExtensionModel.NewsTitle" onkeyup="this.value=this.value.replace(/\s+/g,'')"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="文章标签" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="NewsLabel">
                            <a-input placeholder="请输入标签" v-model="ExtensionModel.NewsLabel" onkeyup="this.value=this.value.replace(/\s+/g,'')"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="文章图片" style="margin-bottom:20px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol">
                            <a-upload name="image"
                                      :data="ParamData"
                                      list-type="picture-card"
                                      class="avatar-uploader"
                                      action="/FileServer/Upload"
                                      :fileList="ExtensionModel.fileList"
                                      @change="imgChanges">
                                <div v-if="ExtensionModel.fileList.length < 1 && this.$route.query.rowType !='select'">
                                    <a-icon type="plus" />
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                        </a-form-model-item>
                        <a-form-model-item label="文章内容" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="NewsContent">
                            <Rich v-bind:value.sync="ExtensionModel.NewsContent" rows="800"></Rich>
                            <!-- <a-input placeholder="请输入内容~" type="textarea" rows="6" v-model="ExtensionModel.NewsContent"></a-input> -->
                        </a-form-model-item>

                        <a-form-item class="textal_c" v-if="this.$route.query.rowType !='select'">
                            <a-button type="primary" class="margin-r" @click="SubmitExtension('ruleForms')">保存</a-button>
                        </a-form-item>

                    </a-form-model>
                </a-card>

            </div>
        </a-layout-content>

    </div>
</template>
<!--<script type="text/javascript" charset="utf-8" src="~/Scripts/ueditor.config.js"></script>
<script type="text/javascript" charset="utf-8" src="~/Scripts/ueditor.all.js"></script>-->
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    //import imagedata from "@/Plugin/UploadDataHelper.js";
    import Rich from "@/components/RichEditor.vue";
    export default {
        name: "MaterialDetail",
        data() {
            return {
                ExtensionModel: {
                    NewsTitle: "",
                    NewsLabel: "",
                    NewsContent: "",
                    fileList: []
                },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                MaterialDetail: [],
                headers: {
                    authorization: 'authorization-text',
                },
                rules: {
                    NewsTitle: [{ message: "请输入文章标题", required: true, trigger: "blur" }],
                    NewsLabel: [{ message: "请输入文章标签", required: true, trigger: "blur" }],
                    NewsContent: [{ message: "请输入文章内容", required: true, trigger: "blur" }]
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                config: {
                    rules: [{ type: 'object', required: true, message: 'Please select time!' }],
                },
                rangeConfig: {
                    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
                },
                length: 0
            };

        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'time_related_controls' });
        },
        components: {
            Rich
        },
        methods: {
            imgChanges: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;
                }
                self.ExtensionModel.fileList = info.fileList;
            },
            // removeImgs: function (info) {
            //     var self = this;
            //     self.ExtensionModel.fileList = info.fileList;
            // },
            loadData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/NewsDetail",
                    data: {
                        id: this.$route.query.id
                    },
                    OnSuccess: function (data) {
                        //self.ExtensionModel = data.data;
                        self.ExtensionModel.NewsTitle = data.data.NewsTitle;
                        self.ExtensionModel.NewsLabel = data.data.NewsLabel;
                        self.ExtensionModel.NewsContent = data.data.NewsContent;
                        if (data.data.TitleImg != "" && data.data.TitleImg != null) {
                            self.ExtensionModel.fileList = [{
                                name: data.data.TitleImg,
                                response: data.data.TitleImg,
                                status: "done",
                                uid: "0",
                                url: data.data.TitleImg
                            }];
                        }
                        else {
                            self.ExtensionModel.fileList = [];
                        }
                    }
                };
                http.Post(op);
            },
            SubmitExtension(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var self = this;
                        if (self.checkImage()) {
                            var op = {
                                url: "/MarketingModule/Material/EditNews",
                                data: {
                                    ID: this.$route.query.id,
                                    NewsTitle: self.ExtensionModel.NewsTitle,
                                    NewsLabel: self.ExtensionModel.NewsLabel,
                                    TitleImg: self.ExtensionModel.fileList.length > 0 ? self.ExtensionModel.fileList[0].response : "",
                                    NewsContent: self.ExtensionModel.NewsContent
                                },
                                OnSuccess: function (response) {
                                    console.log(formName + response);
                                    self.$router.push({ name: 'Material_List' });
                                }
                            };
                            http.Post(op);
                        } else {
                            return false;
                        }
                    }
                });
            },
            checkImage: function () {
                var rt = true;
                var self = this;
                if (self.ExtensionModel.fileList != null) {
                    if (self.ExtensionModel.fileList.length == 0) {
                        rt = false;
                        self.$message.error("请上传图片！");
                    }
                }
                return rt;
            }
        },
        mounted() {
            this.loadData(this.id);
        }
    }
</script>